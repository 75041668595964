import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import Styles from './gallery.module.scss'
import Img from "gatsby-image"

function Gallery({ gallery }) {
    const [toggler, setToggler] = useState({
    toggler: false,
    slide: 1
    });
    
    function openLightboxOnSlide(number) {
    setToggler({
    toggler: !toggler,
    slide: number
    });
    }
    
    return (
    <>

    <section className={Styles.listingHero}>

        <div className={Styles.mainCols}>

            <div className={Styles.mainLeftCol}>
            <Img 
            onClick={ () => openLightboxOnSlide(1) }
            className={Styles.photo} 
            fluid={ gallery.frontOfHouse.localFile.childImageSharp.fluid } 
            />
            </div>

            <div className={Styles.mainRightCol}>
                    <div className={Styles.innerLeftCols}>
                        <div className={Styles.firstLeftCol}>
                        <Img 
                        onClick={ () => openLightboxOnSlide(2) }
                        className={Styles.photo} 
                        fluid={ gallery.bathroom.localFile.childImageSharp.fluid } 
                        />
                        </div>
                        <div className={Styles.firstRightCol}>
                        <Img
                        onClick={ () => openLightboxOnSlide(3) } 
                        className={Styles.photo} 
                        fluid={ gallery.kitchen.localFile.childImageSharp.fluid } 
                        />
                        </div>
                    </div>
                    <div className={Styles.innerRightCols}>
                        <div className={Styles.secondLeftCol}>
                        <Img 
                        onClick={ () => openLightboxOnSlide(4) }
                        className={Styles.photo} 
                        fluid={ gallery.livingRoom.localFile.childImageSharp.fluid } 
                        />
                        </div>
                        <div className={Styles.secondRightCol}>
                        <Img 
                        onClick={ () => openLightboxOnSlide(5) }
                        className={Styles.photo} 
                        fluid={ gallery.backyard.localFile.childImageSharp.fluid } 
                        />
                        </div>
                    </div>
            </div>
        </div>
    </section>
  
    <FsLightbox
    toggler={ toggler }
    sources={ [
        gallery.frontOfHouse.url,
        gallery.bathroom.url,
        gallery.kitchen.url,
        gallery.livingRoom.url,
        gallery.backyard.url,
    ] }
    slide={ toggler.slide }
    />
    </>
    );
    }

export default Gallery;
