// Components - separate component required?:
// 1. Image Gallery (y)
// 2. Title and description (n)
// 3. Bedroom (y)
// 4. Bills (n)
// 5. Facilities (n)
// 6. Map (y)

import React from "react"
import { graphql, Link } from 'gatsby'
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Wrapper from "../components/layout/wrapper";
import Gallery from "../components/listing/gallery"
import Map from "../components/listing/map"
import Img from "gatsby-image"
import Style from "./listing.module.scss"
// import { styles } from "ansi-colors";

const ListingTemplate = ({ data: { prismicListing: { data } } }) => {
    
    return (
        <Layout>
          <SEO title={data.title.text} />
          <Gallery gallery={data.image_gallery} />
          <section className={Style.listingBody}>
                <Wrapper>
                    <div className={Style.colsBody}>
                        <div className={Style.colLeft}>
                           <Wrapper>
                                <h4>{data.listing_type}</h4>
                                <h1>{data.title.text}</h1>
                                <p>{data.description.text}</p>
                                <hr />
                           </Wrapper>
                           <Wrapper>
                                <h2>Available Rooms</h2>
                                <p>Please note the rent shown below is the base price. Rent may vary slightly based on availability and time of year. Actual prices will be provided once you have made a request and before you make any payments. </p>
                                <div>
                                    {data.bedroom.map(b => (
                                      <div className={Style.bedBlock}  key={b.bedroom_name.text}>
                                          <Img
                                          className={Style.bedPhoto} 
                                          fluid={ b.bedroom_image.localFile.childImageSharp.fluid }
                                           />
                                          <div>
                                            <h3>{b.bedroom_name.text}</h3>
                                            <h5>${b.weekly_rent} per week</h5>
                                            <p>{b.bedroom_type} ∙ {b.bed_size} bed ∙ {b.bedroom_bathroom} bathroom</p>
                                          </div>
                                      </div>
                                    ))}
                                </div>
                                <hr />
                           </Wrapper>
                           <Wrapper>
                                <h2>Bills</h2>
                                <p>Your weekly rent will include the following bills.</p>
                                <ul>
                                    {data.bills.map(x => (
                                        <li key={x.bill}>{x.bill}</li>
                                    ))}
                                </ul>
                                <hr />
                           </Wrapper>
                           <Wrapper>
                                <h2>Facilities</h2>
                                <p>This property has the following facilities available.</p>
                                <ul>
                                    {data.facilities.map(f => (
                                        <li key={f.facility}>{f.facility}</li>
                                    ))}
                                </ul>
                                <hr />
                           </Wrapper>
                           <Wrapper>
                                <h2>Location</h2>
                                <Map
                                latitude={data.address.latitude}
                                longitude={data.address.longitude} 
                                />
                           </Wrapper>
                        </div>
                        <div className={Style.colRight}>
                            <h4>From ${data.starting_rent} <small>per week</small></h4>
                            <hr/>
                            <Link type="button" 
                            className={Style.requestButton}
                            to="/request" 
                            state={{fromListing: data.title.text}}
                            >
                            Apply now
                            </Link>
                            <hr className={Style.widgetDivider}/>
                            <small>
                                To make a booking request, click the button above and you will be
                                taken to a brief form where you can enter some details to help
                                our team confirm your booking.
                            </small>
                        </div>
                    </div>
                </Wrapper>
            </section>

            {/* Mobile Fixed Widget */}
            <div className={Style.mobileFixedWidget}>
                <Wrapper>
                    <div className={Style.mobileFixedWidgetButtonContainer}>
                        <h4 className={Style.mobileFixedWidgetPrice}>
                            From ${data.starting_rent} <small>p.w</small>
                        </h4>
                        <Link
                         to="/request" className={Style.mobileFixedWidgetButton}
                         state={{fromListing: data.title.text}}
                         >
                            Apply now
                        </Link>
                    </div>
                </Wrapper>
            </div>

        </Layout>
      )
}

export default ListingTemplate

export const query = graphql`
query ListingTemplateQuery($uid: String!) {
    prismicListing(uid: { eq: $uid }) {
        uid
      data {
        title {
          text
        }
        description {
          text
        }
        listing_type
        starting_rent
        minimum_stay
        location {
          document {
            data {
              location
            }
          }
        }
        address {
          latitude
          longitude
        }
        bedroom {
          bedroom_name {
            text
          }
          bedroom_type
          bedroom_bathroom
          bed_size
          weekly_rent
          bedroom_image {
            localFile {
              childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        bills {
          bill
        }
        facilities {
          facility
        }
        image_gallery {
          backyard {
            url
            localFile {
              childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
              }
            }
          }
          bathroom {
            url
            localFile {
              childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
              }
            }
          }
          frontOfHouse {
            url
            localFile {
              childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
              }
            }
          }
          kitchen {
            url
            localFile {
              childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
              }
            }
          }
          livingRoom {
            url
            localFile {
              childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
              }
            }
          }
          localFile {
            url
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`