import React, { Component } from 'react'
import { Map, TileLayer, Circle } from 'react-leaflet'

export default class MyMap extends Component {
    render() {
        const position = [this.props.latitude, this.props.longitude]
        if (typeof window !== 'undefined') {
          return (
          <>
          <Map
                center={position} zoom={14}
                  style={{height: '340px', zIndex: 1}}
                  scrollWheelZoom={false}
                >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                />
                <Circle center={position} color="#0b4141" fillColor="#0b4141" radius={200}/>
              </Map>
          </>
          );
        }
        
        return null;
      }
}
